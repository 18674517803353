import React from "react";

const AboutUs = () => {
  const gameName = "Ludo Bullet";
  return (
    <div
      className="leftContainer"
      style={{ minHeight: "100vh", height: "100%" }}
    >
      <div className="mt-5 py-4 px-3">
        <div className="mb-5">
          <h1>About Us</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About Us
              </li>
            </ol>
          </nav>
          <p>
            Welcome to <strong>{gameName}</strong>, where your favorite
            childhood game comes alive on your device! We are an enthusiastic
            team of developers and game lovers who have come together to create
            a fun and engaging digital version of Ludo, a game that has been
            cherished for generations.
          </p>

          <h4>Our Mission</h4>
          <p>
            At {gameName}, our mission is to bring joy and entertainment to
            every household. We aim to create a seamless gaming experience that
            allows players to connect with their friends and family, no matter
            where they are. We believe that games like Ludo strengthen bonds and
            bring people together, whether you're playing casually with friends
            or in competitive mode.
          </p>

          <h4>Why {gameName}?</h4>
          <ul>
            <li>
              <strong>Easy to Play</strong>: Whether you're new to Ludo or a
              seasoned pro, {gameName} is easy to understand and fun for all
              ages.
            </li>
            <li>
              <strong>Multiplayer Mode</strong>: Play with friends or family in
              real-time, wherever you are.
            </li>
            <li>
              <strong>Customization</strong>: Make the game your own with
              customizable tokens and boards.
            </li>
            <li>
              <strong>Exciting Features</strong>: Experience unique game modes,
              leaderboards, rewards, and much more.
            </li>
          </ul>

          <h4>Contact Us</h4>
          <p>
            Got questions, feedback, or suggestions? We’d love to hear from you!
            Reach out to our support team and we'll be happy to assist you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
