import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../css/layout.css";
import "../css/login.css";

export default function Footer() {
  const location = useLocation();
  const isActive = (path) => location.pathname === path ? "active" : "";

  return (
    <>
      <div>
        <div className="footer_menu">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/" className={isActive("/")}>
              <i className="fa-solid fa-house"></i>
            </Link>
            <Link to="/wallet" className={isActive("/wallet")}>
              <i className="fa-solid fa-wallet"></i>
            </Link>
            <Link to="/profile" className={isActive("/profile")}>
              <i className="fa-solid fa-user"></i>
            </Link>
            <Link to="/support" className={isActive("/support")}>
              <i className="fa-solid fa-headset"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
