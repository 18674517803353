import React, { useEffect, Profiler, useState } from "react";
import "./Component-css/Downloadbutton.css?v=0.1";

const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <div className=" download_btn">
      <div className="d-flex justify-content-center">
        <button
          onClick={onClick}
          className="gamedownloadbtn"
        >
          <i className="fa fa-android fs-1 h4 m-0" />
          <span
            style={{
              color: "#ffffff",
             
              fontWeight: "700",
            }}
          >
            DOWNLOAD OUR APP
          </span>
          <i className="fa fa-download fs-1 h4 m-0" />
        </button>
      </div>
    </div>
  );
};

export default Downloadbutton;
