import React, { useEffect, useState } from "react";
import css from "../css/Mywallet.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

const Mywallet = () => {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  }, []);

  return (
    <>
      <div className="leftContainer">
        <div className="px-4 mt-5 pt-4">
          <div className="main_area d-flex justify-content-between align-items-center">
            <div className="back_btn">
              <Link to="/" className="btn">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>Back
              </Link>
            </div>
            <div className="back_btn">
              <Link to="/transaction-history" className="btn">
                {/* <picture className="ml-4">
                <img
                  width="32px"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/order-history.png"
                  }
                  alt=""
                />
              </picture> */}
                Wallet History
              </Link>
            </div>
          </div>
          <div className="back_btn my-3">
            <div className="wallet_box mt-0">
              <Link
                className={`boxes w-100 d-flex align-items-center justify-content-between`}
                to="/wallet"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <img
                    src="/Images/avatars/Avatar6.png"
                    alt="wallet"
                    width={"30px"}
                  />
                  <div
                    className={`${css.mytext} text-light ml-2 text-custum font-weight-bold`}
                  >
                    Verified
                  </div>
                  <i
                    class="fa fa-check-circle-o text-success ml-1"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="btn bg-custum-new rounded">
                  Verification Completed
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* <div
          className={`${css.divider_x} XXsnipcss_extracted_selector_selectionXX snipcss0-0-0-1 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center`}
        ></div> */}

        <div className="px-4 pb-5 bg-light">
          <div className="wallet_card card">
            <div
              className="bg-1 text-white text-center rounded py-1"
              style={{ fontSize: "1.3em", fontWeight: "800" }}
            >
              Deposit Chips
            </div>
            <div className="info p-2 bg-secondary text-center">
              यह चिप्स Win अवं Buy की गई चिप्स है इनसे सिर्फ गेम खेले जा सकते
              है, बैंक या UPI से निकाला नहीं जा सकता है
            </div>
            <div className="d-flex justify-content-center">
              <div className="shadow rounded py-2 px-5 mt-3 text-center">
                <picture className="mr-1">
                  <img
                    width="50px"
                    src={
                      process.env.PUBLIC_URL +
                      "Images/LandingPage_img/global-rupeeIcon.png"
                    }
                    alt="chips"
                  />
                </picture>
                <p style={{ fontSize: "1.3em", fontWeight: "900" }}>
                  {user && user.Wallet_balance}
                </p>
                <p className="mb-0">Chips</p>
              </div>
            </div>
            <Link to="/addcase" className="px-2 my-3">
              <button className="bg-1 text-white w-100 rounded font-weight-bold py-2 border-0">
                Add
              </button>
            </Link>
          </div>

          <div className="wallet_card card mt-4 mb_5">
            <div
              className="bg-1 text-white text-center rounded py-1"
              style={{ fontSize: "1.3em", fontWeight: "800" }}
            >
              Winning Chips
            </div>
            <div className="info p-2 bg-secondary text-center">
              यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है, इन्हे बैंक या
              UPI में निकाला जा सकता है, इन चिप्स से गेम भी खेला जा सकता है
            </div>
            <div className="d-flex justify-content-center">
              <div className="shadow rounded py-2 px-5 mt-3 text-center">
                <picture className="mr-1">
                  <img
                    width="50px"
                    src={
                      process.env.PUBLIC_URL +
                      "Images/LandingPage_img/global-rupeeIcon.png"
                    }
                    alt="chips"
                  />
                </picture>
                <p style={{ fontSize: "1.3em", fontWeight: "900" }}>
                  {user && user.withdrawAmount}
                </p>
                <p className="mb-0">Chips</p>
              </div>
            </div>
            <Link to="/Withdrawopt" className="px-2 my-3">
              <button className="bg-1 text-white w-100 rounded font-weight-bold py-2 border-0">
                Withdraw
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="rightContainer">
        <div>
          <Rightcontainer />
        </div>
      </div>
    </>
  );
};
export default Mywallet;
