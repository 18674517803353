import React, { useEffect, useState } from "react";
import css from "../css/Refer.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
// import 'remixicon/fonts/remixicon.css'

const Refer = () => {
  const [user, setUser] = useState();
  const Cashheader = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(`https://apifrontlive.ludobullet.com/me`, { headers })
      .then((res) => {
        setUser(res.data);
        // console.log(res.data);
        Allgames(res.data.referral_code);
      })
      .catch((e) => {
        alert(e.msg);
      });
  };

  const [cardData, setGame] = useState([]);

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
   
    await axios
      .get(`https://apifrontlive.ludobullet.com/me/referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data);
        console.log(res.data);
      });
  };

  useEffect(() => {
    Cashheader();
    //eslint-disable-next-line
  }, []);

  const copyCode = (e) => {
   
    const referralLink = `https://ludobullet.com/me/login/${user.referral_code}`;
    navigator.clipboard.writeText(referralLink);
    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  return (
    <div>
      <div className="leftContainer">
        <div className={`${css.center_xy} pt-5`}>
          <picture className="d-flex justify-content-center">
            <img
              alt="img"
              width="270px"
              src={process.env.PUBLIC_URL + "Images/refer/refer_earn.png"}
              className="snip-img"
            />
          </picture>
          <div className="mt-3 mb-1">
            {/* <div className="mt-3 text-center font-9">
              Current Earning:
              <b>
                {user.referral_wallet}
              </b>
              <Link className="ml-2" to="/Redeem">
                Redeem
              </Link>
            </div> */}

            <div class="refer_box mb-1">
              <div class="font-15 text-center bg-secondary">
                Earn now unlimited<span aria-label="party-face">🥳</span>
              </div>
              <div class="d-flex justify-content-center font-weight-bold ">
                Refer your friends now!
              </div>
              <div class="d-flex justify-content-around my-2">
                <div class="border-right w-50 text-center">
                  <div>Total Refers:</div>
                  <b>{cardData && cardData}</b>
                </div>
                <div class="text-center font-9 w-50">
                  <div>Total Earned:</div>
                  <b>₹ {user.referral_earning}</b>
                </div>
              </div>
            </div>
            {/* <div className={`${css.progress}`}>
              <div
                className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`}
                aria-valuenow={user.referral_earning}
                aria-valuemax={10000}
                style={{ width: `${(user.referral_earning * 200) / 10000}%` }}
              ></div>
            </div> */}
            {/* <div className="font-9">
              <span>Max: 10,000</span>
              <Link className="float-right" to="/update-pan">
                Upgrade Limit
              </Link>
            </div> */}

            <div className="Refer_text_bold__yedrJ mt-3 text-center refer_box">
              <div className="font-15 text-center bg-secondary mb-1">
                Referral Code
              </div>
              <div className="d-flex p-3 align-items-stretch">
                <h4 className="border-1 border w-100 text-left px-2 m-0 refer_code">
                  {user.referral_code}
                </h4>
                <button
                  onClick={(e) => copyCode(e)}
                  className="cash_btn py-1 w-100"
                >
                  Copy Code
                </button>
              </div>
            </div>
          </div>
          <div className="refer_box my-3">
            <div
              className={`${css.font_11} ${css.text_bold} bg-secondary p-2 text-center`}
            >
              Refer &amp; Earn Rules
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/giftbanner.png"}
                  className="snip-img"
                />
              </picture>
              <div className={`${css.font_9} mx-3`} style={{ width: "63%" }}>
                <div>
                  When your friend signs up on Ludo Bullet from your referral
                  link,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get
                  <strong>&nbsp; 2% Commission</strong>
                  &nbsp;on your
                  <strong>&nbsp; referral's winnings.</strong>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/banner.png"}
                  className="snip-img"
                />
              </picture>
              <div className={`${css.font_9} mx-3`} style={{ width: "63%" }}>
                <div>
                  Suppose your referral plays a battle for a 10000 Cash,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get
                  <strong>a 200 Cash</strong>
                  <strong></strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${css.refer_footer} pt-2 `}>
          <a
            href={`whatsapp://send?text=Play Ludo and earn Rs10000 daily.Commission Charge - 5% OnlyReferral - 2% On All Games.24x7 whats app Chat Support Instant Withdrawal Via UPI/Bank Register Now, My refer code is    👇  ${user.referral_code}.

     https://ludobullet.com/login/${user.referral_code}`}
            style={{ width: "100%", color: "white" }}
          >
            <div class="bg-green text-white rounded referbtn_refer cxy w-100 p-2">
              Share on Whatsapp
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Refer;
